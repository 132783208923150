import type { GameDefinition } from "@/app/game-definition.mjs";
import { devError } from "@/util/dev.mjs";

const required = {
  gameSymbol: Symbol,
  icon: String,
  isActive(value: GameDefinition<symbol>["isActive"]) {
    return value === "comingSoon" || typeof value === "boolean";
  },
  fullName: [String],
  shortName: String,
  gameColor: String,
  navLinks: [Object],
  settings: Function,
  routes: [Object],
};

// Here is the runtime check for GameDefinition!
export default function checkGameDefinition(
  gameDefinition: GameDefinition<symbol>,
) {
  for (const key in required) {
    if (!Object.hasOwn(gameDefinition, key)) {
      devError(`Missing "${key}" in game definition`, gameDefinition);
      continue;
    }
    const v = gameDefinition[key as keyof GameDefinition<symbol>];
    const check = required[key as keyof typeof required];
    if (Array.isArray(check)) {
      const [t] = check;
      if (!Array.isArray(v)) {
        devError(`Key "${key}" must be an array`, gameDefinition);
        continue;
      }
      for (const x of v) {
        if (x?.constructor !== t) {
          devError(`Value of "${key}" must be ${t.name}`, gameDefinition);
        }
      }
    }
    if (typeof check === "function") {
      if (check.toString().includes("native")) {
        if (v?.constructor !== check) {
          devError(`Value of "${key}" must be ${check.name}`, gameDefinition);
        }
      } else {
        const result = check(v);
        if (!result) {
          devError(`Value of "${key}" failed check`, gameDefinition);
        }
      }
    }
  }
}
